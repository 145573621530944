export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 5,
    path: "/rooms",
    text: "Rooms",
    subItems: [
      {
        id: 51,
        path: "/hotel",
        text: "Hotel",
      },
      {
        id: 52,
        path: "/homestay",
        text: "Homestay",
      },
      {
        id: 53,
        path: "/holiday",
        text: "Holiday",
      },
    
    ],
  },
  {
    id: 4,
    path: "/listyourproperty",
    text: "List Your Property",
  },

  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
];
export const socialIcons = [
  {
    name: "Facebook",
    icon: <i className="fab fa-facebook-f"></i>,
    link: "https://www.facebook.com/share/Tp7ivn7v9w9uvWYd/?mibextid=qi2Omg",
  },
  {
    name: "Twitter",
    icon: <i className="fab fa-twitter"></i>,
    link: "https://www.twitter.com",
  },
  {
    name: "LinkedIn",
    icon: <i className="fab fa-linkedin-in"></i>,
    link: "https://www.linkedin.com/company/say-rooms/",
  },
  {
    name: "Instagram",
    icon: <i className="fab fa-instagram"></i>,
    link: "https://www.instagram.com/sayroomsandhomes?igsh=dTNrdWcyNWJ3ZGhq",
  },
  {
    name: "YouTube",
    icon: <i className="fab fa-youtube"></i>,
    link: "https://youtube.com/@sayroomshomes3508?si=Pi1sbl2giKZ3svcx",
  },
];
  
export const carouselData = [
  {
    img: "../assets/img/carousel-1.jpg",
    title: "Need A Stay? Just 'SAY'",
    subtitle: "SAY Rooms & Homes",
    btn1: "Our Room",
    btn2: "Book Room",
  },
  {
    img: "../assets/img/carousel-2.jpg",
    title: "Need A Stay? Just 'SAY'",
    subtitle: "SAY Rooms & Homes",
    btn1: "Our Room",
    btn2: "Book Room",
  },
];
 export const cities = [
    { name: 'Darjeeling', locations: ['Darjeeling', 'Latpanchar', 'Coming Soon'] },
    { name: 'Jalpaiguri', locations: ['Chalsa', 'Coming Soon', 'Coming Soon'] },
    { name: 'Kalimpong', locations: ['Rishop', 'Coming Soon', 'Coming Soon'] },
    { name: 'Sikkim', locations: ['Gangtok', 'Tarey Bhir', 'Coming Soon'] },
    { name: 'Kolkata', locations: ['Salt Lake', 'Coming Soon', 'Coming Soon'] },

  ];

export const about = [
  
];


export const team = [
  {
    image: "../assets/img/team-1.jpg",
    name: "Rahul Chaudhury",
    designation: "Founder & CEO",
    socials: {
      linkedin: "https://www.linkedin.com/in/rahul-chaudhury-5b149093/",
    },
  },
  {
    image: "../assets/img/team-2.jpg",
    name: "Jaydeep Bose",
    designation: "Co-Founder & COO",
    socials: {
      linkedin: "https://www.linkedin.com/in/jaydeep-bose-a4618115/",
    },
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Chiranjeeb Banik",
    designation: "Co-Founder & CFO",
    socials: {
      linkedin: "https://www.linkedin.com/in/chiranjeebbanik-939b3a15/",
    },
  },
];




export const footerItem = [
  {
    id: 1,
    header: "Quick links",
    UnitItem: [
      { name: "Home" },
      { name: "About Us" },
      { name: "Hotel" },
      { name: "Homestay" },
      { name: "Holiday" },
      { name: "List your property" },
      { name: "Contact Us" },
    ],
  },
  {
    id: 2,
    header: "Others Links",
    UnitItem: [
      { name: "Our Blogs" },
      { name: "How we Work" },
      { name: "Careers" },
      { name: "Help and Support" },  // Add this link
      { name: "Privacy Policy" },    // Add this link
      { name: "TermsAndConditions" }, // Add this link
    ],
  },
];


export const footerContact = [
 
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Registered Office - 246/5, 207/1 RBC ROAD, Kolkata-700028",
  },
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Siliguri - 2, Nivedita Road, Pradhan Nagar, Siliguri-734003",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "+919382167169",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "booking@sayrooms.com",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "Booking",
    email: "booking@sayrooms.com",
  },

  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "General",
    email: "booking@sayrooms.com",
  },
];
export const testimonial = [
  {
    description:
      "Magical stay. I was greeted with warm smiles and genuine hospitality from the hosts. Clean rooms, stunning view of Kanchenjunga. Delicious homemade meals served. I highly recommend Say rooms and homes.",
    name: "Shripad patil",
    Location: "Darjeeling",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "It is awesome property..very natural environment and all facilities. Hospitality is also very good. The way of greeting the guests is unique...feel good.",
    name: "Shilpa",
    Location: "Darjeeling",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },

  {
    description:
      "from say rooms very nice .they r very adjustable and good in behaviour.room r very good,clean.",
    name: "Nabanita Banerjee",
    Location: "Darjeeling",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "The best things we can tell about Say homes the coordination and constant communication and try to resolve the matter in quick succession. And also the choose of cab driver is very good in terms of behaviour and conduct. And also help in economical upgradation of the local people by recommending home stay.",
    name: "Anjan Ghosh",
    Location: "Darjeeling",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const roomItems = [
  {
    img: "../assets/img/room-1.jpg",
    price: "₹1299/night",
    name: "SAY Rooms Trip Manager Guest House, Kolkata",
   
    description:
      "Bestowing excellent hospitality to its every category of guests, Say Rooms Trip Manager Guest Housereflects the culture and ethos of its location. With the perfect fusion of contemporary decor in the sumptuous interiors of the hotel, the hotel has set the bar of services much higher than the expectations of its guests. The concept of intuitive anticipatory service makes the guest experience unalloyed. The hotel welcomes and offers hospitality to guests coming from all around the world. We are focused on delivering high quality, the best value, and superior customer service for an ultimate and intimate travelling experience.Say Rooms Trip Manager Guest House is a hotel with a stylish elegance here contemporary style, new age amenities, and essential services combine to give travelers a harmonious experience. Our hotel has all the services that are needed, and all the essentials are in place. Innovation in services served at the hotel and benchmarked products soaked in the essence of its location forms the basis of our commitment to responsible luxury that creates the milestones of our hotels value proposition.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Highlighted: ["Lounge", "Caretaker", "Free Parking", "Seating Area", "Balcony/Terrace", "7 More"],
      Basic: ["Free Parking", "Power Backup", "Housekeeping", "Air Conditioning", "Free Wi-Fi", "11 More"],
      Transfers: ["Paid Bus Station Transfers", "Paid Airport Transfers", "Paid Pickup/Drop", "Paid Railway Transfers"],
      FoodAndDrinks: ["Dining Area"],
      SafetyAndSecurity: ["Emergency Exit Map", "CCTV", "Safety and Security", "Security"],
      MediaAndTechnology: ["TV"],
      GeneralServices: ["Caretaker", "Luggage Assistance", "Multilingual Staff", "Electrical Sockets", "Doctor on Call"],
      CommonArea: ["Lounge", "Seating Area", "Balcony/Terrace", "Verandah", "Aquarium", "Reception"],
      BusinessCenter: ["Printer", "Photocopying"],
    },
    location: "Downtown",
    images: [
      "../assets/img/k1.png",
      "../assets/img/k2.png",
      "../assets/img/k3.png",
      "../assets/img/k4.png",
      "../assets/img/k5.png",
      "../assets/img/k6.png",
    ],
  },
  {
    img: "../assets/img/room-4.jpg",
    price: "₹1999/night",
    name: "SAY Homes Sai Maple, Darjeeling",
   
    description:
      "Situated 5.9 km from Tiger Hill and 100 metres from Ghoom Monastery, SAY Homes Dream Catcher features accommodation in Darjeeling. Free WiFi is availablethroughout the property. Each unit features a terrace offering city views, a flat-screen TV, a seating area, a well-fitted kitchen and a private bathroom with bath. A car rental service is available at the homestay. Tibetan Buddhist Monastery Darjeeling is 1.3 km from SAY Homes Dream Catcher, while Tiger Hill Sunrise Observatory is 5.9 km from the property. The nearest airport is Bagdogra International, 62 km from the accommodation, and the property offers a paid airport shuttle service.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Living Room", "Lounge", "Caretaker", "Restaurant", "Washing Machine", "5 More"],
      Basic: ["Washing Machine", "Housekeeping", "Free Wi-Fi", "Umbrellas", "Room Service"],
      Transfers: ["Free Shuttle Service", "Paid Airport Transfers"],
      FoodAndDrinks: ["Restaurant"],
      PaymentServices: ["ATM"],
      SafetyAndSecurity: ["CCTV", "Fire Extinguishers", "Security alarms"],
      HealthAndWellness: ["First-aid Services"],
      GeneralServices: ["Caretaker", "Doctor on Call", "Wake-up Call"],
      OutdoorActivities: ["Cycling"],
      CommonArea: ["Living Room", "Lounge", "Seating Area", "Outdoor Furniture"],
      Shopping: ["Souvenir Shop", "Jewellery Shop", "Book Shop"],
      BusinessCenter: ["Business Centre", "Conference Room"],
      PetEssentials: ["Pet bowls", "Pet baskets"],
      OtherFacilities: ["Sitout Area"],
    },
    location: "Downtown",
    images: [
      "../assets/img/sm1.png",
      "../assets/img/sm2.png",
      "../assets/img/sm3.png",
      "../assets/img/sm4.png",
      "../assets/img/sm5.png",
      "../assets/img/sm6.jpg",
      "../assets/img/sm7.png",
      "../assets/img/sm8.png",
      "../assets/img/sm9.png",
      "../assets/img/sm10.png",
      "../assets/img/sm11.png",
    ],
  },
  {
    img: "../assets/img/room-2.jpg",
    price: "₹1999/night",
    name: " SAY Hotel Mount Royal, Darjeeling",
   
    description:
      "Situated 5.9 km from Tiger Hill and 100 metres from Ghoom Monastery, SAY Homes Dream Catcher features accommodation in Darjeeling. Free WiFi is availablethroughout the property. Each unit features a terrace offering city views, a flat-screen TV, a seating area, a well-fitted kitchen and a private bathroom with bath. A car rental service is available at the homestay. Tibetan Buddhist Monastery Darjeeling is 1.3 km from SAY Homes Dream Catcher, while Tiger Hill Sunrise Observatory is 5.9 km from the property. The nearest airport is Bagdogra International, 62 km from the accommodation, and the property offers a paid airport shuttle service.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Living Room", "Lounge", "Caretaker", "Restaurant", "Washing Machine", "5 More"],
      Basic: ["Washing Machine", "Housekeeping", "Free Wi-Fi", "Umbrellas", "Room Service"],
      Transfers: ["Free Shuttle Service", "Paid Airport Transfers"],
      FoodAndDrinks: ["Restaurant"],
    PaymentServices: ["ATM"],
      SafetyAndSecurity: ["CCTV", "Fire Extinguishers", "Security alarms"],
      HealthAndWellness: ["First-aid Services"],
      GeneralServices: ["Caretaker", "Doctor on Call", "Wake-up Call"],
      OutdoorActivities: ["Cycling"],
      CommonArea: ["Living Room", "Lounge", "Seating Area", "Outdoor Furniture"],
      Shopping: ["Souvenir Shop", "Jewellery Shop", "Book Shop"],
      BusinessCenter: ["Business Centre", "Conference Room"],
      PetEssentials: ["Pet bowls", "Pet baskets"],
      OtherFacilities: ["Sitout Area"],
    },
    location: "Downtown",
    images: [
      "../assets/img/mr1.jpg",
      "../assets/img/mr2.jpg",
      "../assets/img/mr3.jpg",
      "../assets/img/mr4.jpg",
      "../assets/img/mr5.jpg",
      "../assets/img/mr6.jpg",
      "../assets/img/mr7.jpg",
      "../assets/img/mr8.jpg",
      "../assets/img/mr9.jpg",
      "../assets/img/mr10.png",
    ],
  },
  {
    img: "../assets/img/room-3.jpg",
    price: "₹1999/night",
    name: "SAY Homes Dream Catcher, Darjeeling",
   
    description:
      "Situated 5.9 km from Tiger Hill and 100 metres from Ghoom Monastery, SAY Homes Dream Catcher features accommodation in Darjeeling. Free WiFi is availablethroughout the property. Each unit features a terrace offering city views, a flat-screen TV, a seating area, a well-fitted kitchen and a private bathroom with bath. A car rental service is available at the homestay. Tibetan Buddhist Monastery Darjeeling is 1.3 km from SAY Homes Dream Catcher, while Tiger Hill Sunrise Observatory is 5.9 km from the property. The nearest airport is Bagdogra International, 62 km from the accommodation, and the property offers a paid airport shuttle service.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Living Room", "Lounge", "Caretaker", "Restaurant", "Washing Machine", "5 More"],
      Basic: ["Washing Machine", "Housekeeping", "Free Wi-Fi", "Umbrellas", "Room Service"],
      Transfers: ["Free Shuttle Service", "Paid Airport Transfers"],
      FoodAndDrinks: ["Restaurant"],
      PaymentServices: ["ATM"],
      SafetyAndSecurity: ["CCTV", "Fire Extinguishers", "Security alarms"],
      HealthAndWellness: ["First-aid Services"],
      GeneralServices: ["Caretaker", "Doctor on Call", "Wake-up Call"],
      OutdoorActivities: ["Cycling"],
      CommonArea: ["Living Room", "Lounge", "Seating Area", "Outdoor Furniture"],
      Shopping: ["Souvenir Shop", "Jewellery Shop", "Book Shop"],
      BusinessCenter: ["Business Centre", "Conference Room"],
      PetEssentials: ["Pet bowls", "Pet baskets"],
      OtherFacilities: ["Sitout Area"],
    },
    location: "Downtown",
    images: [
      "../assets/img/dcd1.png",
      "../assets/img/dcd2.png",
      "../assets/img/dcd3.png",
      "../assets/img/dcd4.png",
      "../assets/img/dcd5.png",
      "../assets/img/dcd6.png",
      "../assets/img/dcd7.png",
      "../assets/img/dcd8.png",
      "../assets/img/dcd9.png",
      "../assets/img/dcd10.png",
    ],
  },

  {
    img: "../assets/img/room-5.jpg",
    price: "₹1999/night",
    name: "SAY Xplorica Dechen Villa, Gangtok,Sikkim",
    
    description:
      "Indulge in the essence of tranquility at Dechen Villa by Say Homes, a stunning villa nestled in the heart of vibrant Gangtok, just a brisk 5-minute stroll from the bustling M.G Marg. This ancestral abode, once graced by an esteemed Bhutanese Princess, boasts a legacy steeped in elegance and serenity. Discover our intimate haven offering six spacious rooms spread across two floors, each thoughtfully designed to capture mesmerizing mountain vistas. Families, groups of friends, and couples alike will find solace in our pet-friendly haven. Expect airy rooms adorned with inviting comforts, ensuring a restful and relaxing stay. Our dining enclave not only promises delectable meals but also presents a panoramic view of the valley. Step outside to our inviting open space, where guests can relish a piping hot cup of tea or coffee, overlooking the rolling hills. Just as your experience at Dechen Villa Hotel, we strive to exceed expectations. Our attentive team ensures impeccable service and a warm welcome throughout your stay. Delight in a culinary journey at our in-house restaurant, offering a diverse menu tailored to cater to varied palates. From tantalizing local delicacies to international gastronomic delights, every dish is crafted with finesse using the finest ingredients. At Dechen Villa by Say Homes, expect an exquisite blend of comfort, personalized service, and unforgettable culinary experiences—a destination that encapsulates unparalleled hospitality and cherished memories for every guest.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Restaurant", "Caretaker", "Power Backup", "Free Wi-Fi", "Housekeeping"],
      Basic: ["Washing Machine", "Housekeeping", "Free Wi-Fi", "Room Service"],
      Transfers: ["Paid Airport Transfers"],
      FoodAndDrinks: ["Restaurant"],
      PaymentServices: ["ATM"],
      SafetyAndSecurity: ["CCTV", "Fire Extinguishers", "Security alarms"],
      HealthAndWellness: ["First-aid Services"],
      GeneralServices: ["Caretaker", "Doctor on Call", "Wake-up Call"],
      OutdoorActivities: ["Cycling"],
      CommonArea: ["Living Room", "Lounge", "Seating Area", "Outdoor Furniture"],
      Shopping: ["Souvenir Shop", "Jewellery Shop", "Book Shop"],
      BusinessCenter: ["Business Centre", "Conference Room"],
      PetEssentials: ["Pet bowls", "Pet baskets"],
      OtherFacilities: ["Sitout Area"],
    },
    location: "Downtown",
    images: [
      "../assets/img/xdv1.jpg",
      "../assets/img/xdv2.jpg",
      "../assets/img/xdv3.jpg",
      "../assets/img/xdv4.jpg",
      "../assets/img/xdv5.jpg",
      "../assets/img/xdv6.jpg",
      "../assets/img/xdv7.jpg",
      "../assets/img/xdv8.jpg",
      "../assets/img/xdv9.jpg",
      "../assets/img/xdv10.jpg",
      "../assets/img/xdv11.jpg",
      "../assets/img/xdv12.jpg",
    ],
  },
  {
    img: "../assets/img/room-6.jpg",
    price: "₹1199/night",
    name: "SAY Forest Club Resort, Chalsa,Jalpaiguri",
    
    description:
      "Situated 12 km from Gorumara National Park, SAY Forest Club Resort in Chālsa has a garden and restaurant. The resort features family rooms. Asian and vegetarian breakfast options are available each morning at the resort. The nearest airport is Bagdogra International Airport, 75 km from SAY Forest Club Resort.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Restaurant", "Caretaker", "Power Backup", "Free Wi-Fi", "Housekeeping"],
      Basic: ["Housekeeping","Room Service"],
      Parking: ["Shared Access", "Onsite Parking is available", "Parking is available for 2 Cars", "Parking available for free"],
    },
    location: "Downtown",
    images: [
      "../assets/img/cfc1.png",
      "../assets/img/cfc2.png",
      "../assets/img/cfc3.png",
      "../assets/img/cfc4.png",
      "../assets/img/cfc5.png",
      "../assets/img/cfc6.png",
      "../assets/img/cfc8.png",
      "../assets/img/cfc9.png",
      "../assets/img/cfc10.png",
      "../assets/img/cfc11.png",
      "../assets/img/cfc12.png",
      "../assets/img/cfc13.png",
      "../assets/img/cfc14.png",
      "../assets/img/cfc15.png",
      "../assets/img/cfc16.png",
    ],
  },
  {
    img: "../assets/img/room-7.jpg",
    price: "₹1999/night",
    name: "SAY Homes Grace, Tarey Bhir,Sikkim",
    
    description:
      "SAY Homes Grace is situated in Namchi. At the homestay, every unit is fitted with a private bathroom. The homestay offers an Asian or vegetarian breakfast. The nearest airport is Pakyong Airport, 75 km from SAY Homes Grace.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Caretaker","Housekeeping"],
      Basic: ["Housekeeping"],
      General: ["Caretaker"],
    },
    location: "Downtown",
    images: [
      "../assets/img/gtb1.jpg",
      "../assets/img/gtb2.jpg",
      "../assets/img/gtb3.jpg",
      "../assets/img/gtb4.jpg",
      "../assets/img/gtb5.jpg",
      "../assets/img/gtb6.jpg",
      "../assets/img/gtb7.jpg",
      "../assets/img/gtb8.jpg",
      "../assets/img/gtb9.jpg",
      "../assets/img/gtb10.jpg",
      "../assets/img/gtb11.jpg",
    ],
  },
  {
    img: "../assets/img/room-8.jpg",
    price: "₹1899/night",
    name: "SAY Homes Rishop, Rishop, Kalimpong ",
    
    description:
      "Welcome to our cozy and inviting homestay nestled in the picturesque paradise of Rishop, Rishyap. Our property offers a serene retreat amidst the majestic mountains, enveloping you in the tranquility of the region's rich flora and fauna. As you book your stay with us on MakeMyTrip (MMT), here's what you can expect: Neat & Clean Rooms: Our priority is your comfort and well-being. Our rooms are meticulously maintained, ensuring cleanliness and a comfortable atmosphere throughout your stay.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Caretaker","Shared Terrace"],
      Terrace: ["Shared Access","Shared Area","Sofa","Outdoor Furniture"],
      Basic: ["Attached Bathroom"],
      Transfers: ["Paid Pickup/Drop"],
      General: ["Caretaker","Multilingual Staff"],
      Other: ["Phone Network","Bonfire Pit"],
    },
    location: "Downtown",
    images: [
      "../assets/img/rishop1.png",
      "../assets/img/rishop2.png",
      "../assets/img/rishop3.png",
      "../assets/img/rishop4.png",
      "../assets/img/rishop5.png",
      "../assets/img/rishop6.png",
      "../assets/img/rishop7.png",
    ],
  },
  {
    img: "../assets/img/room-9.jpg",
    price: "₹1899/night",
    name: "SAY Homes Hornbill View, Latpanchar, Darjeeling",
   
    description:
      "Located in Latpanchar which is a Sittong 3 Forest Village, The homestay offers a beautiful surrounding with trees, cool weather and birds chirping. you can see the hornbill bird and so any other birds . The nature are awesome here, not so cold or hot. it's a pleasant weather. It is a home stay called horn bill view. Rooms are duplex and very authentic local traditional rural food with this.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
    amenities: {
      Popular: ["Caretaker","Housekeeping"],
      Basic: ["Housekeeping"],
      General: ["Caretaker"],
    },
    location: "Downtown",
    images: [
      "../assets/img/hvl1.png",
      "../assets/img/hvl2.JPG",
      "../assets/img/hvl3.png",
      "../assets/img/hvl4.png",
      "../assets/img/hvl5.png",
      "../assets/img/hvl6.png",
    ],
  },
];

export const facility = [
  {
    icon: <i class="fa fa-bed text-primary me-2"></i>,
    quantity: 2,
    facility: "Occupancy",
  },
  {
    icon: <i class="fa fa-bath text-primary me-2"></i>,
    quantity: 1,
    facility: "Bathroom",
  },
  {
    icon: <i class="fa fa-wifi text-primary me-2"></i>,
    facility: "Wifi",
  },
];
export const NewsItem = [
  {
    image: '../assets/img/imgMF/saytrip.JPG',
    title: 'SAY Rooms: ‘Come as a Guest, Go as a Friend’',
    description: 'With an exceptional concept, ‘Say Rooms’, under Say Trip Hospitality targets all types of accommodations, hotels, home stays as primary category of execution...',
    source: 'Netpal Travel',
    url: 'https://www.netpaltravel.com/interview/say-rooms-come-as-a-guest-go-as-a-friend/',
  },
  {
    image: '../assets/img/imgMF/saytrip2.JPG',
    title: '[Startup Bharat] This Siliguri-based hotel management firm wants to become OYO of Northeast India',
    description: 'when OYO Rooms, run by Oravel Stays Pvt Ltd, wowed the startup ecosystem in 2014 by raising huge funding rounds, budget hotels were touted as the next...',
    source: 'YOURSTORY',
    url: 'https://yourstory.com/2022/03/startup-bharat-siliguri-hotel-management-sayrooms-oyo-northeast',
  },
  {
    image: '../assets/img/imgMF/saytrip3.JPG',
    title: '‘COVID is here to stay for a while, but we cannot let it rule our lives’',
    description: 'With India reporting two cases of the Omicron variant of Covid-19, the tourism industry has again been impacted severely this year. Most of the tour operators have said that cancellations have increased during the holiday season ...',
    source: 'SUNDAY GUARDIAN LIVE',
    url: 'https://sundayguardianlive.com/news/omicron-hasnt-hit-domestic-travel-much-say-tour-operators',
  },

 

];

import React from "react";
import { useParams } from "react-router-dom";
import { roomItems } from "../data/Data"; // Import the room data
import Slider from "react-slick"; // Import a slider library (e.g., react-slick)
import "./cityhotels.css"; // Custom CSS for card layout

const CityHotels = () => {
  const { cityName } = useParams(); // Get city name from URL parameter

  // Filter the roomItems based on the city name
  const cityHotels = roomItems.filter((room) =>
    room.name.toLowerCase().includes(cityName.toLowerCase())
  );

  // Function to generate WhatsApp link
  const getWhatsAppLink = (name) => {
    const phoneNumber = "9382167169"; // Replace with your desired WhatsApp number
    const message = `I would like to inquire about booking a room at ${name}.`;
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  };

  // Slider settings to show 5 images at once
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 images at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Adjust for medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2, // Adjust for smaller screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Adjust for mobile devices
        },
      },
    ],
  };

  return (
    <div className="city-hotels">
      <h2>Hotels in {cityName}</h2>
      <div className="hotel-cards">
        {cityHotels.length > 0 ? (
          cityHotels.map((hotel, index) => (
            <div className="card" key={index}>
              <Slider {...settings}>
                {hotel.images.map((img, imgIndex) => (
                  <div className="slider-image" key={imgIndex}>
                    <img
                      src={img}
                      alt={`Slide ${imgIndex + 1}`}
                      className="card-img"
                    />
                  </div>
                ))}
              </Slider>
              <div className="card-body">
                <h5 className="card-title">{hotel.name}</h5>
                <div className="card-price-section">
                  <span className="card-price text-light bg-warning">
                    {hotel.price}
                  </span>
                  <span className="card-original-price">
                    {hotel.originalPrice}
                  </span>
                  <span className="card-discount ">({hotel.discount}% off)</span>
                </div>
                <p className="card-description">
                  {hotel.description.substring(0, 100)}...
                </p>
                <div className="card-actions">
                  <button className="btn btn-details">View Details</button>
                  <button
                    className="btn btn-dark"
                    onClick={() =>
                      window.open(getWhatsAppLink(hotel.name), "_blank")
                    }
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No hotels available for {cityName}</p>
        )}
      </div>
    </div>
  );
};

export default CityHotels;

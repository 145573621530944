import React from "react";
import Heading from "../components/common/Heading";
import "../css/style.css";

const getWhatsAppLink = (altText) => {
  const phoneNumber = "9382167169";
  const message = `May I inquire about the ${altText} package? 😊`;
  return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
};

export default function Holiday() {
  const images = [
    { id: 1, src: '../assets/img/offers1.jpg', alt: 'Darjeeling 2 Night | 3 days' },
    { id: 2, src: '../assets/img/offers2.jpg', alt: 'Darjeeling & Dooars 3 Night | 4 days' },
    { id: 3, src: '../assets/img/offers3.jpg', alt: 'Darjeeling' },
    { id: 4, src: '../assets/img/offers4.jpg', alt: 'Gangtok & Darjeeling  4 Night | 5 days' },
    { id: 5, src: '../assets/img/offers5.jpg', alt: 'Darjeeling & Kalimpong  3 Night | 4 days' },
  ];

  return (
    <>
      <Heading heading="HOLIDAY" title="Home" subtitle="Holiday" />
      <div className="container">
        <p className="h1 text-center text-primary text-uppercase">Holiday details</p>
        <div className="row">
          {images.map((image) => (
            <div key={image.id} className="col-12 col-md-4 mb-4 d-flex justify-content-center">
              <a href={getWhatsAppLink(image.alt)} target="_blank" rel="noopener noreferrer">
                <img src={image.src} alt={image.alt} className="holiday-image" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
